<template>
  <div>
    <a-loader v-if="loading" />
    <card :id="id" />
  </div>
</template>

<script>
export default {
  components: {
    Card: () => import("./card"),
  },
  data() {
    return {
      loading: false,
      id: 0,
    };
  },
  created() {
    this.getNewCard();
  },
  methods: {
    async getNewCard() {
      this.loading = true;
      try {
        const response = await this.$axios.g(this.$root.api.cardGetNew);
        if (response.data.status == "ok") {
          this.$router.push({
            name: "get-card-id",
            params: { id: response.data.data },
          });
        }
      } catch (error) {
        this.$root.$emit("show-info", {
          text: "Больше номеров нет",
        });
      }
      this.loading = false;
    },
  },
};
</script>